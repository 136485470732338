.league-page__title {
  margin: 30px 0;
}

.stats__button {
  color: #5f8bff;
  font-size: 16px;
  // margin-left: auto;
  padding: 10px 20px;
  border-radius: 100px;
  border: 1px solid #5f8bff;
  background: transparent;
  font-weight: 300;
  display: block;
}

.la-league__stats {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

.league-container {
  margin-bottom: 60px;
  gap: 20px;
  display: flex;
  flex-direction: column;
}
