.customization {
  &__title {
    max-width: 750px;
    margin-bottom: 40px;
    font-family: "Montserrat";
  }
  &-grid {
    display: grid;
    grid-template-columns: 190px 298px 410px 300px;
    grid-template-rows: auto;
    column-gap: 30px;
    margin-bottom: 42px;
    row-gap: 10px;
  }

  &-buttons {
    :first-child {
      margin-right: 10px;
    }
  }
}

@media (max-width: 1349px) {
  .customization-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 991px) {
  .customization-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .customization-grid {
    grid-template-columns: 1fr;
  }
}