.referees-card {
  background: white;
  width: 410px;
  height: fit-content;
  padding: 40px;
  box-shadow: 0px 0px 29px 0px rgba(231, 231, 231, 0.16);
  border-radius: 20px;
  &__subtitle {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  &__pointers {
    margin-bottom: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
    div {
        margin-left: 45%;
        &:nth-child(2) {
          margin-left: 26%;
        }
    }
  }
  &__name {
    width: 96px;
    font-size: 16px;
    font-family: Montserrat;
    color: black;
  }
  &__params {
    text-align: center;
    width: 96px;
    color: #808395;
    font-family: Montserrat;
    font-size: 16px;
  }
}
