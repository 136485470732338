.trends {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  &__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Unbounded";
    font-weight: 600;
    font-size: 24px;
    background: white;
    text-align: center;
    cursor: pointer;
    padding: 0px 10px;
    min-height: 130px;
    box-shadow: 0px 0px 29px rgba(231, 231, 231, 0.16);
    border-radius: 10px;
    &:hover {
      background: #5f8bff;
      color: white;
    }

    &:nth-child(8) {
      padding: 0px 60px;
    }
  }

  &__title {
    margin-bottom: 40px;
  }
}
