.team {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &-left {
    margin-right: 30px;
    flex-basis: 850px;
    max-width: 850px;
  }

  &-right {
    display: flex;
    flex-direction: column;
    flex-basis: 410px;
    max-width: 410px;
  }

  &__title {
    margin-bottom: 20px;
    width: 850px;
  }

  &__desc {
    width: 60%;
    font-family: "Montserrat";
    font-weight: 300;
    font-size: 16px;
  }
}
