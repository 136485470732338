.profile {
  &-tabs {
    display: flex;
    flex-basis: 952px;
    max-width: 952px;
    margin-bottom: 35px;
    align-items: center;
    justify-content: space-between;
    &__tab {
      cursor: pointer;
      padding: 0px 10px;
      padding-bottom: 7px;
      font-weight: 300;
      font-size: 16px;
      color: #494c59;
      &_active {
        border-bottom: 1px solid #5f8bff;
        color: #5f8bff;
      }
    }
  }
  &__title {
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 45px;
  }
}

