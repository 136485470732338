.team-title {
  color: #14151b;
  font-family: Unbounded;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.no-data {
  width: fit-content;
  height: fit-content;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 45px 441px;
  border-radius: 20px;

  &__block {
    width: 410px;
    text-align: center;

    h4 {
      color: #5f8bff;
      font-size: 36px;
      font-weight: 600;
      line-height: 43.2px;
    }

    p {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 300;
      line-height: 22.4px;
    }
  }
}
