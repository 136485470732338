.billing-modal {
  &__text {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #808395;
    margin-bottom: 20px;
  }
  &-reasons {
    display: flex;
    flex-direction: column;
    &__item {
      display: flex;
      font-family: "Montserrat";
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }

  &__cancel {
    font-family: "Montserrat";
    font-size: 16px;
    color: #14151b;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  &__label,
  &__textarea {
    font-family: "Montserrat";
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  &__textarea {
    min-height: 140px;
    margin-bottom: 30px;
    width: 100%;
    border-radius: 20px;
    border: 1px solid #808395;
    padding: 10px 20px;
  }

  &__button {
    &_cancel {
      width: 100%;
      flex-grow: 1;
    }
  }
  &-buttons {
    display: flex;
    column-gap: 10px;
    button {
      width: 100%;
    }
  }
}

.billing-radio {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 20px;
  border: 1px solid black;
  margin-right: 5px;
  padding: 4px;
  &__content {
    width: 12px;
    height: 12px;
    min-height: 12px;
    min-width: 12px;
    border-radius: 20px;
    &_active {
      background: #5f8bff;
    }
  }
}
