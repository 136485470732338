.support {
  &__title {
    font-family: "Montserrat";
    font-size: 18px;
    margin-bottom: 20px;
  }

  &-content{
    display: flex; 
    justify-content: space-between;
  }

  &__description {
    font-family: "Montserrat";
    font-size: 14px;
    color: #808395;
    margin-bottom: 40px;
  }

  &-form {
    display: flex;
    flex-direction: column;
    max-width: 850px;
    flex-basis: 850px;
    &__button {
      margin-left: auto;
    }

    &-files {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
      justify-content: flex-end;
      gap: 20px;
    }

    &__file {
      display: flex;
      align-items: center;
      border-radius: 100px;
      border: 1px solid #808395;
      font-family: "Montserrat";
      font-size: 16px;
      padding: 10px;
      img {
        margin-left: 10px;
      }
    }
  }
}

@media (max-width: 1310px){
  .support{
    margin-top: 40px;
    &__title, &__description{
      text-align: center;
    }
    &-content{
      flex-direction: column;
      align-items: center;
    }

    &-form{
      flex-basis: 0;
      margin-bottom: 40px;
      width: 100%;
    }

    &-text{
      max-width: 850px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}