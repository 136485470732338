.contact-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  &__label {
    font-family: "Montserrat";
    font-size: 16px;
    margin-bottom: 5px;
  }

  &__input {
    background: transparent;
    border: 1px solid #808395;
    border-radius: 100px;
    padding: 10px 20px;
    font-family: "Montserrat";
    font-size: 16px;
    color: #808395;
    &:focus {
      outline: 1px solid #5f8bff;
    }

    &_textarea {
      min-height: 140px;
      max-height: 140px;
      border-radius: 30px;
    }
  }
}
