.referee-bar {
  display: flex;
  background: #ffffff;
  margin-bottom: 40px;
  border: 1px solid #5f8bff;
  border-radius: 100px;
  padding: 16px 60px;
  align-items: center;
  &__league {
    font-family: "Montserrat";
    font-size: 16px;
    margin-bottom: 0px;
    margin-right: 30px;
    color: #808395;
  }

  &__img {
    max-width: 54px;
    margin-right: 20px;
  }
  &__name {
    font-family: "Montserrat";
    font-size: 24px;
    margin-right: 20px;
    margin-bottom: 0px;
  }
  &__status {
    font-family: "Montserrat";
    margin-right: 20px;
    margin-bottom: 0px;
    font-size: 16px;
    color: white;
    padding: 2px 5px;
    background: #97cea7;
    border-radius: 100px;
  }
  &-stats {
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    // max-width: 465px;
    // flex-basis: 465px;
    // grid-template-columns: 1fr 1fr;
    // grid-template-rows: 1fr 1fr;
    &__item {
      white-space: nowrap;
      font-family: "Montserrat";
      margin-right: 20px;

      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 20px;
      }

      &:nth-child(3),
      &:nth-child(4) {
        margin-bottom: 0px;
      }

      &:nth-child(1) {
        margin-left: 15px;
      }
      span {
        color: #5f8bff;
        margin-left: 5px;
      }
    }
  }
}
