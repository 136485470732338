.teams-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  &-right {
    display: flex;
    flex-basis: 410px;
    margin-left: 40px;
  }

  &__title {
    font-family: "Unbounded";
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 20px;
  }

  &__desc {
    font-family: "Unbounded";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #494c59;
    margin-bottom: 40px;
  }

  &-filters {
    display: flex;
    flex-direction: column;
  }

  &-left {
    max-width: 850px;
  }
}

.stats__button {
  color: #5f8bff;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 100px;
  border: 1px solid #5f8bff;
  background: transparent;
  font-weight: 300;
}
