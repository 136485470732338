.result-bar {
  display: flex;
  margin-bottom: 40px;
  padding: 40px 60px;
  align-items: center;
  background: white;
  border: 1px solid #5f8bff;
  border-radius: 100px;
  width: 100%;
  &__img {
    max-width: 100px;
    margin-right: 20px;
    object-fit: contain;
  }

  &__name {
    color: #14151b;
    font-family: Unbounded;
    font-size: 20px;
    margin-bottom: 0;
  }

  &__score {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }

  &__coach {
    font-family: Montserrat;
    font-size: 16px;
    color: #808395;
    margin-bottom: 0;
  }

  &-row {
    display: flex;
    flex-basis: 120px;
    justify-content: space-between;
    max-width: 120px;
    margin-right: 20px;

    &__item {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 5px;
      &-win {
        background: #cfff47;
      }
      &-lose {
        background: #ff5f5f;
      }
      &-draw {
        background: #ffc047;
      }
    }
  }

  &__league {
    font-family: Unbounded;
    font-size: 20px;
    color: #808395;
    margin-bottom: 0px;
    margin-left: 20px;
  }
}
