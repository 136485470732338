.paginator {
  display: flex;
  &-text {
    font-family: "Unbounded";
    color: #494c59;
    font-size: 16px;
    font-weight: 300;
    margin-right: 40px;
    margin-bottom: 30px;
    cursor: pointer;
    &__chosen {
      cursor: pointer;
      color: #5f8bff;
      border-bottom: 1px solid #5f8bff;
    }
  }
}
