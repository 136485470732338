.tooltip {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  left: 20px;
  background: white;
  color: black;
  width: 200px;
  top: -50px;
  padding: 10px;
  border: 1px solid #5f8bff;
  border-radius: 20px;
  opacity: 1 !important;
  &_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    &__key {
      font-weight: 700;
    }
  }
}
