.main-content {
  display: flex;
  justify-content: space-between;
}

.leagues {
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  &-picker {
    margin-bottom: 45px;
    display: flex;
    &__tab {
      font-size: 16px;
      font-weight: 300;
      margin-right: 50px;
      :last-child {
        margin-right: 0px;
      }

      &_active {
        color: #5f8bff;
      }
    }
  }
}

@media(max-width:1236px){
  .main-content{
    flex-direction: column;
  }
  .leagues-picker{
    margin-top: 105px;
  }
}
