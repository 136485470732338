.search {
  display: flex;
  background: transparent;
  padding: 10px 20px;
  border: 1px solid #808395;
  border-radius: 100px;
  flex-basis: 323px;
  min-width: 323px;
  margin: 0 15px 0 15px;

  &__input {
    font-size: 16px;
    border: none;
    font-family: "Montserrat";
    color: #808395;
    background: transparent;
    width: 100%;
  }
}
