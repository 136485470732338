.profile-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  &__label {
    font-family: "Montserrat";
    font-size: 16px;
    margin-bottom: 5px;
  }

  &__input {
    width: 100%;
    font-family: "Montserrat";
    font-size: 16px;
    border: 1px solid #14151b;
    border-radius: 100px;
    padding: 10px 20px;
  }
}
