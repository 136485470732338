.success {
  &-modal {
    align-items: center;
    &-top {
      max-width: 500px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
    }
    &__text {
      margin-bottom: 0px;
      margin-left: 20px;
      font-family: "Montserrat";
      font-size: 16px;
      font-weight: 700;
    }
  }
}
