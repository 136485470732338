.timezone {
  &-select {
    max-width: 410px;
    flex-basis: 410px;
    display: flex;
    flex-direction: column;
    &__select {
      font-family: "Montserrat";
      border-radius: 100px;
      border: 1px solid #808395;
      background-color: transparent;
      appearance: none;
      background-repeat: no-repeat;
      background-position: right 1rem center;
      background-size: 1em;
      background-image: url("../../../../../../assets//svg/angle-down.svg");
      font-size: 16px;
      line-height: 20px;
      padding: 10px 20px;
    }
    &__label {
      font-family: "Montserrat";
      margin-bottom: 5px;
      font-size: 16px;
      margin-bottom: 5px;
      line-height: 20px;
    }
  }
}
