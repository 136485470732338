.average-stats__card {
  display: flex;
  width: 410px;
  padding: 40px;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 0px 29px 0px rgba(231, 231, 231, 0.16);
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  color: #14151b;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  &-line {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

.line {
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
}

span:first-child {
  max-width: 277px;
}
