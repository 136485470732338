.stats__cards {
  display: flex;
  justify-content: start;
  height: 140px;
  flex-wrap: wrap;
  gap: 20px 30px;
  &-item {
    width: 190px;
    height: 60px;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 29px 0px rgba(231, 231, 231, 0.16);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    &-w300 {
      width: 300px !important;
    }
  }
}
