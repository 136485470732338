.referees-cards {
  display: flex;
  gap: 30px;

  &__items {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__item {
    width: 629px;
    height: 37px;
    padding: 10px;
    text-align: center;
    border-radius: 100px;
    color: #494c59;
    font-family: Unbounded;
    font-size: 14px;
    font-weight: 300;
  }
}

@media (max-width: 1435px) {
  .referees-cards {
    flex-direction: column;
    gap: 10px;
  }
}
