.stats__percentage {
  display: flex;
  padding: 40px;
  flex-direction: column;
  gap: 20px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 29px 0px rgba(231, 231, 231, 0.16);
  &-title {
    color: #14151b;
    font-family: Unbounded;
    font-size: 20px;
    font-weight: 600;
  }
  &-items {
    display: flex;
    justify-content: space-between;
    max-width: 770px;
  }
  &-item {
    display: flex;
    flex-direction: column;
    &-text {
      color: #494c59;
      font-family: Unbounded;
      font-size: 16px;
      font-weight: 300;
    }
    &-line {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-family: Unbounded;
      font-size: 16px;
      font-weight: 500;
      height: 40px;
      border-radius: 100px;
      &-home {
        background: #97cea7;
        border-radius: 100px 0 0 100px;
      }
      &-draw {
        background: #ffc047;
        border-radius: 100px 0 0 100px;
        margin-left: -40px;
      }
      &-away {
        background: #ff5f5f;
        margin-left: -40px;
      }
      &-goal {
        background: white;
        border: 1px solid black;
        color: #000;
        margin-left: -40px;
        border-radius: 100px 0 0 100px;
      }
    }
  }
}
