.chart__card {
  background: white;
  border-radius: 20px;
  height: 336px;
  display: flex;
  justify-content: space-between;
  padding: 40px;
  box-shadow: 0px 0px 29px 0px rgba(231, 231, 231, 0.16);
  &-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 370px;
    &-title {
      color: #14151b;
      font-family: Unbounded;
      font-size: 20px;
      font-weight: 600;
    }
  }
}
