.contact-us {
  background: url("../img/contact-us.png");

  background-repeat: no-repeat;
  background-position: left;

  &-form {
    display: flex;
    flex-direction: column;
    flex-basis: 410px;
    max-width: 410px;
  }

  &-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    margin-left: auto;
  }
}

.contact__title {
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 36px;
}
