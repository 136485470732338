.league {
  display: flex;
  white-space: nowrap;
  width: 100%;
  // max-width: 850px;
  flex-direction: column;
  margin-bottom: 20px;
  cursor: pointer;
  border: 1px solid #5f8bff;
  border-radius: 30px;

  &__icon {
    max-width: 30px;
    max-height: 21px;
  }

  &-state {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0px;
    flex-basis: 70px;
    min-width: 70px;
    margin-left: auto;
    margin-right: 20px;
    color: #ffffff;
    border-radius: 5px;
    margin-bottom: 0px;
    font-family: "Montserrat";
    white-space: nowrap;
    font-weight: 500;
    font-size: 10px;
    max-width: 70px;
    &_pre {
      background: #ffc047;
    }
    &_fin {
      background: #d9d9d9;
    }
    &_live {
      background: #ff5f5f;
    }
  }

  &_dark {
    border-color: #cfff47;
  }

  &-score {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0px;
    margin-left: 20px;
  }

  &-odds {
    display: flex;
    margin-left: 10px;
    :first-child {
      margin-right: 10px;
    }
    &__item {
      font-size: 10px;
      font-family: "Montserrat";
      border-radius: 5px;
      color: #808395;
      padding: 5px;
      border: 1px solid #808395;
      margin-right: 5px;
      margin-bottom: 0px;
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    padding: 23px;
    border: 1px solid #5f8bff;
    border-radius: 30px;
    width: 100%;
    border: none;
    border-top: none;
    &_active {
      border-bottom: 1px solid #5f8bff;
    }

    &_dark {
      border-color: #cfff47;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    &_active {
      transition: max-height 0.2s ease-out;
      max-height: 100%;
    }
  }

  &-name {
    display: flex;
    align-items: center;
  }

  &__favorite {
    margin-right: 33px;
  }

  &__title {
    font-family: "Montserrat";
    font-size: 16px;
    margin-bottom: 0px;
    margin-left: 20px;
  }

  &-match {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    padding: 22px;

    &_dark {
      border-bottom: 1px solid #262832;
    }
    &-name {
      display: flex;
      align-items: center;

      margin-bottom: 0px;
    }

    &:last-child {
      border-bottom: none;
    }

    &__name {
      margin-bottom: 0px;
      font-family: "Montserrat";
      font-size: 16px;
      margin-left: 20px;
      max-width: 201px;
      min-width: 201px;
      white-space: break-spaces;
      color: #5f8bff;
      &_dark {
        color: white;
      }
    }

    &__time {
      font-family: "Montserrat";
      font-size: 16px;
      margin-left: 22px;
      margin-bottom: 0px;
    }

    &__referee {
      font-family: "Montserrat";
      margin: 0px 15px;

      min-width: 115px;
      max-width: 115px;
      white-space: break-spaces;
      font-size: 16px;
      margin-bottom: 0px;
    }
  }

  &__arrow {
    transition: all 0.3s ease-in;
    &_active {
      transition: all 0.3s ease-in;
      transform: rotate(180deg);
    }
  }
}

.league-header_active.league_header_dark {
  border-bottom: 1px solid #cfff47;
}
