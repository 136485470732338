.learning {
  position: relative;
  &__title {
    margin-bottom: 20px;
  }

  &__desc {
    font-family: "Montserrat";
    margin-bottom: 40px;
    max-width: 960px;
    font-size: 18px;
  }

  &-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__player {
    flex-basis: 630px;
    top: 10px;
    position: sticky;
    max-width: 630px;
    height: 400px;

    border-radius: 32px;
    margin-right: 30px;
    background-image: url("../img/player.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__lesson {
    display: flex;
    max-width: 630px;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 0px 29px rgba(231, 231, 231, 0.16);
    border-radius: 20px;
    padding: 40px;
    margin-bottom: 30px;
    cursor: pointer;
  }

  &__text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 0px;
  }

  &-topic {
    display: flex;
    margin-bottom: 30px;
  }

  &__name {
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 20px;
    margin-right: 40px;
  }

  &__time {
    font-family: "Montserrat";
    font-size: 18px;
    margin-bottom: 0px;
  }
}
