.blog-card {
  display: flex;
  flex-direction: column;
  padding: 40px;
  flex-basis: 410px;
  max-width: 410px;
  background: #ffffff;
  box-shadow: 0px 0px 29px rgba(231, 231, 231, 0.16);
  border-radius: 20px;
  &-header {
    align-items: center;
    margin-bottom: 30px;
    display: flex;
  }

  &-body {
    display: flex;
    flex-direction: column;
  }

  &-footer {
    display: flex;
    justify-content: space-between;
  }

  &__type {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    color: white;
    border-radius: 100px;
    font-weight: 300;
    max-height: 27px;
    margin-right: 30px;
    font-size: 14px;
    &_red {
      background: #ff5f5f;
    }
    &_yellow {
      background: #ffc047;
    }

    &_green {
      background: #97cea7;
    }

    &_blue {
      background: #5f8bff;
    }
  }

  &__time {
    font-weight: 300;
    margin-bottom: 0px;
    font-size: 14px;
    color: #494c59;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
  }

  &__desc {
    font-family: "Montserrat";
    font-size: 18px;
    margin-bottom: 30px;
  }

  &__read {
    font-family: "Montserrat";
    font-size: 18px;
    text-decoration-line: underline;
    color: #5f8bff;
  }
}
