.grid-item {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: white;
  padding: 10px;
  font-weight: 300;
  font-size: 16px;
  line-height: 19.2px;
  &-checkbox {
    border-radius: 2px;
    min-width: 14px;
    min-height: 14px;
    max-height: 14px;
    max-width: 14px;
    cursor: pointer;
    border: 1px solid #808395;
    margin-right: 10px;
  }
}
