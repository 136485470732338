.tooltipReferee {
    display: flex;
    flex-direction: column;
    position: absolute;
    text-align: center;
    z-index: 2;
    right: 20px;
    background: white;
    color: black;
    width: 150px;
    top: -35px;
    padding: 10px;
    border: 1px solid #5f8bff;
    border-radius: 20px;
    opacity: 1 !important;
    &_item {
        font-weight: 700;
    }
}