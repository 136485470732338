@import "~bootstrap/scss/bootstrap";

*:focus {
  outline: none;
}

body {
  overflow-x: hidden;
  zoom: 0.8;
}

li {
  list-style-type: none; /* Убираем маркеры */
}
ul {
  margin-left: 0; /* Отступ слева в браузере IE и Opera */
  padding-left: 0; /* Отступ слева в браузере Firefox, Safari, Chrome */
  margin-bottom: 0px;
}

body {
  color: black;
  font-family: "Unbounded";
}

.title {
  font-family: "Unbounded";
  font-weight: 600;
  font-size: 36px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
