.leaguesAndCups__card {
  display: flex;
  width: 410px;
  height: fit-content;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 0px 29px 0px rgba(231, 231, 231, 0.16);
  &-title {
    color: #14151b;
    font-family: Unbounded;
    font-size: 20px;
    font-weight: 600;
  }
  &__item {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding-bottom: 11px;
  }
  &__img {
    width: 30px;
    height: 20px;
    border-radius: 3px;
  }
  &__text {
    text-decoration: none;
    color: #14151b;
    font-family: Montserrat;
    font-size: 16px;
    margin-bottom: 0;
  }
  &-loadmore {
    color: #5f8bff;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    cursor: pointer;
    text-decoration-line: underline;
    text-align: center;
  }
}
