.confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  &-message {
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 630px;
    padding: 40px 0px;
    background: white;
    border: 1px solid rgb(238, 237, 237);
  }

  &__text {
    font-family: "Montserrat";
    font-size: 16px;
    margin-bottom: 0px;
    margin-left: 20px;
  }
}
