.google {
  display: flex;
  width: max-content;
  cursor: pointer;
  border: 1px solid #5f8bff;
  border-radius: 100px 100px 0px 100px;
  padding: 12px 25px;
  padding-right: 38px;
  margin-bottom: 30px;
  &__icon {
    margin-right: 10px;
  }

  &__text {
    font-family: "Montserrat";
    font-size: 16px;
    margin-bottom: 0px;
  }
}
