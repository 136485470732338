.time-filter {
  align-self: flex-start;
  cursor: pointer;
  display: flex;

  border-radius: 100px 100px 100px 100px;
  border: 1px solid #808395;
  overflow: hidden;
  border-right: none;
  &__item {
    border-right: 1px solid #808395;
    padding: 10px;
    font-size: 14px;
    font-weight: 300;
    white-space: nowrap;

    &-disabled {
      border-right: none;
      border-left: none;
    }

    &_active {
      background: #cfff47;
    }
  }

  &__input {
    display: flex;
    padding: 10px;
    font-size: 14px;
    font-weight: 300;
    max-width: 55px;
    background: white;
    justify-content: center;
    text-align: center;
    align-items: center;
    border: 1px solid#5f8bff;
    padding: 10px;
    &-second {
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
    }
  }
}
