.header-dropdown {
  display: flex;
  position: relative;
  flex-basis: 193px;
  min-width: 193px;
  max-width: 193px;
  flex-direction: column;
  &--mobile{
    margin-top: 22px;
    margin-left: auto;
    flex-basis: 0;
  }
  &-top {
    border-radius: 25px 50px 0px 25px;
    background: #5f8bff;
    font-family: "Montserrat";
    font-size: 16px;
    padding: 12px 38px 10px 25px;
    color: white;
    cursor: pointer;
    &__img {
      margin-left: 17px;
      transform: rotate(-180deg);
      &_active {
        transform: rotate(0);
      }
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    flex-basis: 193px;
    min-width: 193px;
    position: absolute;
    top: 46px;
    border-radius: 20px 0px 20px 20px;
    border: 1px solid #5f8bff;
    background: white;
    overflow: hidden;
    z-index: 2;
  }

  &__item {
    padding: 10px;
    font-family: "Montserrat";
    font-size: 14px;
    color: #14151b;
    cursor: pointer;
    &:hover {
      color: white;
      background: #5f8bff;
    }
    &:last-child {
      border-top: 1px solid #5f8bff;
    }
  }
}
