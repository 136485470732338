.table {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  &-load {
    margin: 0 auto;
    cursor: pointer;
    color: #5f8bff;
    margin-top: 8px;
    line-height: 20px;
    font-family: "Montserrat";
    padding-bottom: 2px;
    border-bottom: 1px solid #5f8bff;
  }
  &-header {
    width: fit-content;
    display: grid;
    background: #5f8bff;
    border-radius: 10px 10px 0px 0px;
    border-right: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;

    p {
      cursor: pointer;
      font-family: "Montserrat";
      font-size: 14px;
      color: white;
      margin-bottom: 0px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #5074d1;
      &:last-child {
        border: none;
        cursor: unset;
      }
      &:first-child {
        cursor: unset;
      }
    }

    span {
      width: 95%;
    }

    &__cell_active {
      &::after {
        content: url("../../assets//svg/stats-arrow.svg");
        margin-left: 4px;

        top: -2px;
      }
    }
  }

  &-coach {
    width: 100% !important;
    padding: 10px;

    p {
      margin: 0px !important;
      padding: 0px !important;
      color: #5f8bff;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.07px;
    }
    
  }

  &-row {
    display: grid;
    background: white;
    text-align: center;
    width: fit-content;
    p {
      border: none;
    }
    &__w {
      background: rgba(151, 206, 167, 0.4) !important;
    }
    &__l {
      background: rgba(255, 95, 95, 0.4) !important;
    }
    &__d {
      background: rgba(255, 192, 71, 0.4) !important;
    }
    &__lastmatches {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1px;
      border-right: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      &-item {
        width: 10px;
        height: 10px;
        border-radius: 100%;
      }
    }
    border-bottom: 1px solid #d9d9d9;
    &:last-child {
      border-radius: 0px 0px 10px 10px;
      p {
        &:last-child {
          border-bottom-right-radius: 10px;
        }
      }
    }
    span {
      font-family: "Montserrat";
      color: black;
      font-size: 14px;
      margin-bottom: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #d9d9d9;
      padding: 12px 0px;
      word-break: break-all;
      &:last-child {
        border-right: none;
      }
      &:nth-child(2) {
        border-left: 1px solid #d9d9d9;
      }
      &:first-child {
        max-width: 388px !important;
        border-left: none;
        border-right: none;
      }

      a {
        text-decoration: none;
        color: black;
      }
    }
    border-right: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;

    &__button {
      background: #5f8bff;
      border-radius: 5px;
      padding: 5px 10px;
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 10px;
      color: white;
      border: none;
    }
  }
}

.reverse {
  &::after {
    transform: rotate(-180deg);
  }
}

input[type="checkbox"] {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid #808395;
}

// @media (max-width: 992px) {
//   .table {
//     &-header {
//       grid-auto-flow: column !important;
//       grid-template-columns: auto !important;
//       max-width: 100% !important;
//     }
//     &-row {
//       grid-auto-flow: column !important;
//       grid-template-columns: auto !important;
//       width: 100% !important;
//     }
//   }
// }
