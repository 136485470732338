.minimum-filter {
  display: flex;
  font-weight: 300;
  font-size: 14px;
  height: min-content;
  align-items: center;
  &__item {
    border: 1px solid #808395;
    padding: 10px;
    font-size: 14px;
    font-weight: 300;
    &:last-child {
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
      border-left: none;
    }

    &:first-child {
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      border-right: none;
    }

    &_active {
      background: #cfff47;
    }
  }

  &__input {
    display: flex;
    text-align: center;
    align-items: center;
    border: 1px solid#5f8bff;
    max-width: 55px;
    justify-content: center;
    font-weight: 300;
    font-size: 14px;
    padding: 10px;
  }
}
