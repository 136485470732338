.league-dropdown {
  position: relative;
  &-head {
    margin: 0px 20px;
    display: flex;
    border: 1px solid #808395;
    padding: 10px;
    border-radius: 100px;
    max-width: 105px;
    cursor: pointer;
  }

  &__title {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
    font-weight: 300;
    justify-content: start;
    color: #494c59;
    border-radius: 100px;
    margin-right: 10px;
  }

  &-body {
    position: absolute;
    background: white;
    left: 20px;
    border-radius: 10px;
    border: 2px solid #5f8bff;
    display: flex;
    flex-direction: column;
    flex-basis: 323px;
    max-width: 323px;
    min-width: 323px;
  }

  &-list {
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    height: 234px;
    max-height: 234px;
    overflow-y: scroll;
  }

  &__item {
    display: flex;
    align-items: center;

    font-family: "Montserrat";
    color: #14151b;
    font-weight: 400;
    padding: 5px 10px;
    cursor: pointer;
    &:hover {
      background: #5f8bff;
      color: white;
      .league-dropdown__checkbox_active {
        background: black;
        border: 1px solid white;
      }
    }
  }

  &-search {
    padding: 10px;
  }

  &-input {
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
  }

  &__input {
    font-family: "Montserrat";
    font-size: 14px;
    color: #808395;
    border: none;
  }

  &__checkbox {
    min-width: 14px;
    min-height: 14px;
    max-width: 14px;
    max-height: 14px;
    margin-right: 10px;
    border-radius: 2px;
    border: 1px solid #808395;
    &_active {
      background: #5f8bff;
    }
  }
}
