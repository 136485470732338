.button {
  font-size: 16px;
  font-family: "Montserrat";
  padding: 12px 38px 10px 25px;
  gap: 10px;
  border-radius: 25px 50px 0px 25px;
  color: white;
  border: none;
  max-height: 42px;
  @media(max-width:768px) {
    margin-top: 22px;
  }
  &_blue {
    min-width: 115px;
    background: #5f8bff;
    &_dark {
      background: #cfff47;
      color: black;
    }
  }
  &_black {
    min-width: 125px;
    background: #14151b;
    &_dark {
      color: black;
      background: #f8f8f8;
    }
  }

  &_gray {
    background: #d9d9d9;
    color: #14151b;
  }
}


