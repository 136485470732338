.account {
  &-cards {
    display: flex;
    justify-content: space-between;

    &-left {
      display: flex;
      flex-direction: column;
      flex-basis: 630px;
      max-width: 630px;
    }

    &__logout {
      padding-top: 30px;

      p {
        font-size: 18px;
        cursor: pointer;
        font-family: "Montserrat";
        margin-left: auto;
        text-decoration: underline;
      }

      display: flex;
    }

    &-right {
      flex-basis: 630px;
      max-width: 630px;
    }

    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 40px;
      background: white;
      box-shadow: 0px 0px 29px rgba(255, 95, 95, 0.07);
      border-radius: 15px;

      p {
        margin-bottom: 0px;
      }
    }

    &__warning {
      font-family: "Montserrat";
      font-size: 18px;
      color: #ff5f5f;
    }

    &__delete {
      font-family: "Montserrat";
      font-size: 18px;
      color: #ff5f5f;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  &__button {
    margin-left: auto;
  }
}

@media (max-width: 1320px) {
  .account-cards {
    flex-direction: column;
    align-items: center;
  }

  .account-card {
    height: auto;
  }

  .account-cards-left {

    order: 1;
  }

  .account-cards-left,
  .account-cards-right {
    flex-basis: 0;
    margin-bottom: 30px;
    width: 100%;
  }

  .account-cards__logout {
    padding-top: 0;
    justify-content: center;
  }

  .account-cards__logout p {
    margin-left: 0;
    // text-align: center;
  }
}