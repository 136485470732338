.registration {
  &__error {
    color: #ff5f5f;
    font-family: "Montserrat";
    font-size: 16px;
    margin-bottom: 20px;
  }
  &-modal {
    display: flex;
    flex-direction: column;
    &__inbox {
      color: #808395;
      text-align: center;
      font-family: "Montserrat";
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    &__email {
      color: #262832;
      text-align: center;
      font-family: "Montserrat";
      font-size: 16px;
      margin-bottom: 20px;
      span {
        font-weight: 700;
      }
    }

    &__resend {
      color: #262832;
      text-align: center;
      font-family: "Montserrat";
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 30px;
      span {
        cursor: pointer;
        color: #5f8bff;
        font-weight: 700;
      }
    }
  }
  &-content {
    display: flex;
    justify-content: center;
  }

  &__title {
    margin-bottom: 30px;
  }

  &-form {
    max-width: 630px;
    flex-basis: 630px;
    display: flex;
    flex-direction: column;
    padding: 40px;
    background: white;
    box-shadow: 0px 0px 29px rgba(231, 231, 231, 0.16);
    border-radius: 20px;
    &__account {
      font-family: "Montserrat";
      font-weight: 600;
      margin-bottom: 20px;
      font-size: 16px;
      max-width: 550px;
      text-align: center;
      span {
        color: #5f8bff;
        border-bottom: 1px solid #5f8bff;
        cursor: pointer;
      }
    }

    &__policy {
      max-width: 310px;
      font-family: "Montserrat";
      margin: 0 auto;
      font-size: 16px;
      text-align: center;
      color: #808395;
      span {
        color: #5f8bff;
        border-bottom: 1px solid #5f8bff;
      }
    }
  }

  &__button {
    margin-left: auto;
    margin-bottom: 40px;
  }
}

.with-email {
  font-family: "Montserrat";
  font-size: 16px;
  color: #808395;
  margin-bottom: 30px;
}

.auth-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  &__label {
    font-family: "Montserrat";
    font-size: 16px;
    margin-bottom: 5px;
  }

  &__input {
    padding: 10px 20px;
    border: 1px solid #808395;
    border-radius: 100px;
    font-size: 16px;
    font-family: "Montserrat";
    &_error {
      color: #ff5f5f !important;
    }
  }
}
