.policy {
  &__block {
    width: 850px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #14151b;

    &_dark {
      color: #fff;
    }

    h1 {
      margin: 0;
      font-family: "Unbounded", sans-serif;
      font-weight: 600;
      font-size: 36px;
      line-height: 120%;
    }

    h2 {
      margin: 0;
      font-family: "Unbounded", sans-serif;
      font-weight: 600;
      font-size: 32px;
      line-height: 120%;
    }

    p {
      margin: 0;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
    }

    h3 {
      margin: 0;
      font-family: "Unbounded", sans-serif;
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
    }

    &__paragraph {
      font-family: "Montserrat", sans-serif !important;
      font-weight: 400 !important;
      font-size: 18px !important;
      line-height: 140% !important;
    }

    ul {
      list-style: none;

      li {
        span {
          margin-right: 20px;
          font-family: "Unbounded", sans-serif;
          font-weight: 700;
          font-size: 18px;
          line-height: 160%;
          color: #5f8bff !important;
        }
      }
    }

    &__span {
      margin-right: 26px !important;
    }

    &__h4 {
      margin: 0;
      font-family: "Unbounded", sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 120%;
    }
  }
}
