.stats-table {
  display: flex;
  flex-direction: column;
  &-load {
    margin: 0 auto;
    cursor: pointer;
    color: #5f8bff;
    margin-top: 8px;
    line-height: 20px;
    font-family: "Montserrat";
    padding-bottom: 2px;
    border-bottom: 1px solid #5f8bff;
  }
  &-header {
    display: grid;
    grid-template-columns: 210px 200px 160px 200px 200px 160px 50px 110px;
    background: #5f8bff;

    border-radius: 10px 10px 0px 0px;

    p {
      cursor: pointer;
      font-family: "Montserrat";
      font-size: 14px;
      color: white;
      margin-bottom: 0px;
      display: flex;
      justify-content: center;
      border-right: 1px solid #5074d1;
      padding: 10px 0px;
      &:last-child {
        border: none;
        cursor: unset;
      }
      &:first-child {
        cursor: unset;
      }
    }

    &__cell_active {
      &::after {
        content: url("../../assets//svg/stats-arrow.svg");
        margin-left: 4px;

        top: -2px;
      }
    }
  }

  &-row {
    display: grid;
    grid-template-columns: 210px 200px 160px 200px 200px 160px 50px 115px;
    background: white;
    text-align: center;
    &:last-child {
      :first-child {
        border-bottom-left-radius: 10px;
      }
      &:last-child {
        border-bottom-right-radius: 10px;
        overflow: hidden;
      }
    }
    p {
      font-family: "Montserrat";
      color: black;
      font-size: 14px;
      margin-bottom: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      padding: 12px 0px;
      &:last-child {
        border-right: none;
      }
      &:first-child {
        border-left: 1px solid #d9d9d9;
      }
    }
    border-right: 1px solid #d9d9d9;

    &__button {
      background: #5f8bff;
      border-radius: 5px;
      padding: 5px 10px;
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 10px;
      color: white;
      border: none;
    }
  }
}

.reverse {
  &::after {
    transform: rotate(-180deg);
  }
}
