.footer {
  padding-top: 100px;
  padding-bottom: 100px;
  padding-right: 90px !important;
  border-top: 1px solid #d9d9d9;

  span {
    color: #5f8bff;
  }
  &_dark {
    color: #f8f8f8;
    span {
      color: #cfff47;
    }
  }

  &-row {
    display: grid;
    grid-template-columns: 156px 130px 102px 128px 272px;
    justify-content: space-between;
    margin-bottom: 48px;
    align-items: flex-start;
  }

  &-logo {
    margin-bottom: 20px;
  }

  &-column {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    &__text {
      text-decoration: none;
      color: black;
      margin-bottom: 10px;
      font-family: "Montserrat";
      line-height: 20px;
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        color: black;
      }

      &_sale {
        font-weight: 600;
        color: #5f8bff;
      }
    }
  }

  &-content {
    max-width: 958px;
  }

  &-text {
    font-family: "Montserrat";
    font-size: 14px;
    padding-right: 10px;

    margin-bottom: 20px;
  }

  &-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
    &__item {
      font-size: 14px;
      font-family: "Montserrat";
    }
  }

  &-copyright {
    font-family: "Montserrat";
    font-size: 14px;
    color: #808395;
  }
}
