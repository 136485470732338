.league-bar {
  display: flex;
  background: #ffffff;
  margin-bottom: 100px;
  border: 1px solid #5f8bff;
  border-radius: 100px;
  padding: 40px 60px;
  align-items: center;
  justify-content: space-between;
  &__league {
    font-family: "Montserrat";
    font-size: 16px;
    margin-bottom: 0px;
    margin-right: 30px;
    color: #808395;
  }

  &__img {
    max-width: 54px;
    margin-right: 20px;
  }
  &__name {
    font-family: "Montserrat";
    font-size: 24px;
    margin-right: 20px;
    margin-bottom: 0px;
  }
  &__status {
    font-family: "Montserrat";
    margin-right: 20px;
    margin-bottom: 0px;
    font-size: 16px;
    color: white;
    padding: 2px 5px;
    background: #97cea7;
    border-radius: 100px;
  }
  &-stats {
    display: flex;
    max-width: 500px;
    flex-wrap: wrap;
    justify-content: end;
    gap: 20px 20px;
    &__item {
      font-family: "Montserrat";
      margin-left: 5px;
      span {
        color: #5f8bff;
        margin-left: 5px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .league-bar {
    flex-direction: column;
    text-align: center;
    align-items: center;
    height: 184px;
  }
  .league-bar-stats {
    margin-left: unset;
    margin-top: 33px;
  }
}
