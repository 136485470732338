.odds-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &__label {
    margin-bottom: 5px;
    color: #bababa;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 16px;
  }

  &__input {
    &:focus {
      outline: 1px solid #5f8bff;
    }

    border: 1px solid #808395;
    border-radius: 5px;
    padding: 10px;
    background: transparent;
    font-family: "Montserrat";
    font-size: 16px;
    &_dark {
      &:focus {
        outline: 1px solid #cfff47;
      }
    }
  }
}


@media(max-width: 1236px){
  
}