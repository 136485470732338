.stats-card {
  width: 770px;
  background: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0px 0px 29px 0px rgba(231, 231, 231, 0.16);

  p {
    color: #14151b;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }

  h4 {
    color: #494c59;
    text-align: center;
    font-family: Unbounded;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 0;
  }
}
