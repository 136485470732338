.errorLayout {
    display: flex;
    flex-direction: column;
    height: 125vh;
    min-width: 100vw;
    background-color: #fbfbfb;
    background-image: url("../assets/svg/bg-top.svg"), url("../assets/svg/bg-bottom.svg");
    background-position: left top, right bottom;
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 70px;
    input {
      color: black;
    }
  
    &_dark {
      background-color: #14151b;
      color: white;
      input {
        color: white;
      }
    }
  }
  
  .content {
    padding: 100px 0;
    flex: auto;
  }
  
  @media (max-width: 768px) {
    .content {
      padding-top: 36px;
    }
  }
  