.converter {
  display: flex;
  position: sticky;
  top: 10px;
  flex-direction: column;
  flex-basis: 410px;
  max-width: 410px;

  &__title {
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 20px;
  }

  &__desc {
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 37px;
  }

  &-payout {
    display: flex;
    border: 1px solid blue;
    border-radius: 5px;
    padding: 21px 0px;
    margin-bottom: 30px;
    margin-top: 10px;

    &_dark {
      border: 1px solid #cfff47;

      .converter-payout__desc {
        color: #cfff47;
      }
    }

    &-left,
    &-right {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__title {
      font-family: "Montserrat";
      font-size: 16px;
      margin-bottom: 10px;
    }

    &__desc {
      color: #5f8bff;
      font-size: 16px;
      margin-bottom: 0px;
    }

    &__button {
      margin-left: auto;
    }

    &-divider {
      background: #d9d9d9;
      width: 1px;
    }
  }
}

.calculator {
  display: flex;
  flex-direction: column;

  &-grid {
    display: grid;
    grid-template-columns: 190px 190px;
    grid-template-rows: auto auto;
    gap: 0px 30px;
  }
}

@media(max-width:1236px) {
  .converter {
    max-width: 100%;
  }

  .calculator {
    flex-direction: row;
    // flex-wrap: wrap; 
    justify-content: space-between;
  }

  .calculator-top {
    display: flex;
    flex-direction: column;
    // max-width: 520px;
    flex-basis: 520px;
    // width: 100%;
    margin-right: 30px;
  }

  .calculator-bottom {
    // max-width: 390px;
    // width: 100%;
    // max-height: 147px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 390px;
  }

  .converter-payout{
    max-height: 167px;
    height: 100%;
  }

  .calculator-grid {
    gap: 0 49px;
    grid-template-columns: 245px 245px;
  }
}