.blog {
  &__title {
    margin-bottom: 20px;
  }

  &-topic {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  &-left {
    margin-right: 30px;
  }

  &-text {
    margin-top: 40px;
    margin-bottom: 63px;
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
    }

    &__desc {
      font-family: "Montserrat";
      font-size: 18px;
      margin-bottom: 0px;
    }

    &__icon {
      margin-bottom: auto;
      margin-right: 20px;
    }
  }

  &-input {
    display: flex;
    flex-basis: 410px;
    max-width: 410px;
    border: 1px solid #5f8bff;
    border-radius: 100px;
    background: transparent;
    font-family: "Montserrat";
    font-size: 16px;
    padding: 10px 20px;
  }

  &-subscribe {
    display: flex;
    flex-direction: column;
    &-submit {
      display: flex;
      justify-content: space-between;
    }

    &__text {
      margin-bottom: 20px;
      font-family: "Montserrat";
      font-size: 18px;
    }
  }

  &-posts {
    display: grid;
    padding-top: 30px;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}
