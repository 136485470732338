.others-card {
  background: white;
  border-radius: 20px;
  box-shadow: 0px 0px 29px 0px rgba(231, 231, 231, 0.16);
  width: 410px;
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  &__col {
    color: #808395;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    &:first-child {
      margin-right: 102px;
    }
    &:last-child {
      margin-left: 40px;
    }
  }
  &__text {
    text-decoration: none;
    color: #14151b;
    font-family: Montserrat;
    font-size: 14px;
    line-height: 110%;
    margin-bottom: 0;
    &:first-child {
      width: 110px;
    }
    &:nth-child(2) {
      width: 20px;
      text-align: center;
      margin-left: 0px;
    }
    &:last-child {
      width: 115px;
      text-align: center;
    }
  }
}
