.errorPage__block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  gap: 66px;

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 46px;

    img {
      width: 523px;
      height: auto;
    }
  }

  &__sms {
    font-family: "Unbounded", sans-serif;
    font-weight: 600;
    font-size: 36px;
    line-height: 120%;
    text-align: center;
    color: #5f8bff;
    &_dark {
      color: #cfff47;
    }
  }

  &__text {
    width: 430px;
    text-align: center;
    font-family: "Unbounded", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #262832;

    &_dark {
      color: #fff;
    }
  }

  &__button {
    border-radius: 100px 100px 0 100px;
    padding: 12px 38px 10px 25px;
    border: none;
    outline: none;
    background: #5f8bff;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #fff;

    &_dark {
      background: #cfff47;
      color: #14151b;
    }
  }
}
