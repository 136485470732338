.leagues-card {
  background: white;
  flex-direction: column;
  display: flex;
  width: fit-content;
  padding: 40px 30px 40px 40px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 20px;
  margin-top: 60px;
  &__item {
    text-decoration: none;
    color: black;
    display: flex;
    border-bottom: 1px solid #D9D9D9;
    gap: 20px;
    width: 100%;
    padding-bottom: 11px;
  }
  &__img {
    width: 30px;
    height: 20px;
  }
  &__text {
    font-family: Montserrat;
    font-size: 16px;
    margin-bottom: 0;
  }
}
