.date-picker {
  display: flex;
  // border: 1px solid #808395;
  // border-radius: 100px;
  overflow: hidden;
  margin-bottom: 10px;
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #808395;
    border: 1px solid #808395;
    padding: 23px 17px;
    cursor: pointer;

    &:first-child {
      // border-right: none;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
    }

    &:last-child {
      // border-right: none;
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
    }

    &_active {
      background: #cfff47;
    }
  }

  &__text {
    color: #494c59;
    font-weight: 300;
    margin-bottom: 0px;
    &_dark {
      color: white;
    }
  }
}

.date-picker__item_active.date-picker__item_dark {
  background: #5f8bff;
}

.date-input {
  border: 1px solid #808395;
  border-radius: 100px;
  color: #494c59;
  padding: 23px 17px;
  background: transparent;
  font-weight: 300;
  cursor: pointer;
  &::-webkit-clear-button {
    display: none;
  }
  &::-ms-clear {
    display: none;
  }
  &_dark {
    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
  }
}

.dates {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
