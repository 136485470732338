.support-input {
  display: flex;
  max-width: 410px;
  flex-basis: 410px;
  flex-direction: column;
  &__input {
    padding: 10px 20px;
    display: flex;

    border-radius: 100px;

    border: 1px solid #808395;
    background: transparent;
    color: #808395;
    font-size: 16px;
    font-family: "Montserrat";
    &:focus {
      border: 1px solid #5f8bff;
    }
  }

  &__label {
    font-family: "Montserrat";
    font-size: 16px;
    margin-bottom: 5px;
  }
}
