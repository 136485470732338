.matches {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  padding: 22px 0px;
  font-weight: 600;
  padding-bottom: 130px;
  margin-bottom: 105px;
  // background-image: url("../../../../assets/img/ball_bg.png");
  // background-size: contain;
  // background-repeat: no-repeat;
  // background-position: right;

  &-img {
    position: absolute;
    right: 0px;
  }

  &-text {
    flex-basis: 520px;
    // max-width: 520px;
    display: flex;
    flex-direction: column;
  }

  &__title {
    z-index: 1;
    width: fit-content;
    font-weight: 600;
    font-size: 64px;
    margin-bottom: 30px;

    &::after {
      content: "";
      position: relative;
      z-index: -1;
      transform: translateY(-80%);
      display: block;
      width: 115%;
      background-color: #cfff47;
      height: 50%;
      border-radius: 0px 0px 100px 0px;
    }

    &_dark {
      &::after {
        background: #5f8bff;
      }
    }
  }

  &__desc {
    font-weight: 300;
    font-size: 20px;
    width: 100%;
  }

  &-wrapper {
    display: flex;
    // margin-left: 64px !important;
  }

  @media (max-width: 1236px) {
    // margin-bottom: 50px;
    // padding-bottom: 65px;

    .matches-text {
      flex-basis: 470px;
    }

    .matches-img {
      margin-left: 0;
    }
  }

  @media (max-width: 768px) {
    // background-position: calc(1vw + 370px) center;
    .matches-text {
      flex-basis: 354px;
    }

    .content {
      margin-top: 36px;
    }
  }
}
