.support-info {
  display: flex;
  flex-basis: 410px;
  max-width: 410px;
  margin-bottom: 30px;
  background: white;
  padding: 40px;
  border-radius: 20px;
  flex-direction: column;

  &__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 30px;
  }

  &__description {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 16px;
    span {
      color: #5f8bff;
    }
    b {
      font-weight: 600;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
