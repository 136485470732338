.custom-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: 630px;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0px 0px 29px 0px rgba(231, 231, 231, 0.16);
  background: white;
  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    top: 0;
    left: 0;
    position: fixed;
    min-width: 100vw;
    min-height: 100vh;
    backdrop-filter: blur(10px);
    background-color: rgba(20, 21, 27, 0.3);
  }

  &-content {
    display: flex;
    flex-direction: column;
  }

  &-body {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  &__close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }

  &__title {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: center;
    color: #5f8bff;
  }
}
