.odds {
  &-grid {
    display: flex;
    flex-wrap: wrap;

    gap: 10px;
    margin-bottom: 30px;
  }
  &-buttons {
    :first-child {
      margin-right: 10px;
    }
  }
}
