.premium {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  flex-basis: 410px;
  max-width: 410px;
  min-width: 410px;
  padding-top: 30px;
  padding-bottom: 46px;
  padding-left: 40px;
  padding-right: 23px;
  background: url("../../assets/img/premium_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  &__title {
    font-family: "Unbounded";
    font-weight: 600;
    font-size: 36px;
  }
  &__desc {
    font-size: 18px;
    font-weight: 300;
  }
  &__button {
    width: fit-content;
    margin-top: 266px;
    span {
      margin-left: 10px;
    }
  }
}

@media(max-width: 1236px){
  .premium{
    max-width: 100%;
  }
}
