.billing-card {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background: white;
  flex-basis: 630px;
  max-width: 630px;
  border-radius: 20px;
  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  &-date,
  &-area {
    display: flex;
    justify-content: space-between;
    font-family: "Montserrat";
    font-size: 16px;
    line-height: 20px;
    p {
      margin-bottom: 0px;
    }
  }

  &-date {
    margin-bottom: 20px;
  }

  &-area {
    display: flex;
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #808395;
    border-radius: 20px;
    span {
      color: #5f8bff;
      border-bottom: 1px solid #5f8bff;
      cursor: pointer;
    }
  }

  &__img {
    margin-right: 20px;
  }

  &__text {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  &__button {
    margin-left: auto;
  }
}
