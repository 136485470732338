.line-filter {
  align-self: flex-start;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  border-radius: 100px 100px 100px 100px;
  border: 1px solid #808395;

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__item {
    border-right: 1px solid #808395;
    padding: 10px;
    font-size: 14px;
    font-weight: 300;
    overflow: hidden;
    white-space: nowrap;
    &:last-child {
      border-right: none;
    }

    &_active {
      background: #cfff47;
    }
  }
}

.previous-filter {
  &-body {
    position: absolute;
    z-index: 3;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    border: 2px solid #5f8bff;
    width: 323px;
    background: white;
    border-radius: 10px;
    padding: 10px;
    flex-wrap: wrap;
  }

  &-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    min-width: 186px;
    font-size: 14px;
    font-weight: 300;
    color: #494c59;
    padding: 10px;
    border-radius: 100px 0px 0px 100px;
    border: 1px solid #808395;
    span {
      margin-right: 10px;
    }
  }

  &-grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, 92px); /* 2 */
    grid-gap: 5px 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    min-width: 92px;
    cursor: pointer;
    font-weight: 300;
    margin-right: 5px;
    padding: 10px 15px;
    border: 1px solid #808395;
    border-radius: 100px;
    margin-bottom: 10px;
    &_active {
      color: #494c59;
      background: #cfff47;
    }
  }

  &__button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #5f8bff;
    border-radius: 100px;
    color: white;
    font-family: "Montserrat";
    padding: 5px 30px;
    margin-left: auto;
    margin-top: auto;
  }
}
