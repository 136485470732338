.header {
  display: flex;
  flex-direction: column;

  &-top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #d9d9d9;
  }

  &-bottom {
    display: flex;
    padding-top: 20px;
    justify-content: space-between;
    align-items: center;
  }

  &-icons {
    display: flex;
    align-items: center;
    flex-basis: 280px;
    max-width: 280px;
    justify-content: space-between;
  }

  &-nav {
    display: flex;
    flex-basis: 608px;
    max-width: 608px;
  }

  &-buttons {
    display: flex;
    flex-basis: 250px;
    max-width: 250px;
    justify-content: space-between;
  }
}

.nav-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-right: 15px;

  li + li {
    margin-left: 15px;
  }

  &__link {
    font-family: "Montserrat";
    font-size: 16px;
  }
}

@media (max-width: 1524px) {
  .container {
    // margin: 0 20px 0 20px;
    max-width: 99% !important;
  }
}

@media (max-width: 768px) {
  .header-buttons {
    flex-basis: 0;
    margin-left: auto;
  }
}
