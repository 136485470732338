.file-input {
  display: flex;
  flex-basis: 410px;
  max-width: 410px;
  flex-direction: column;

  &-element {
    display: flex;

    align-items: center;
    border: 1px solid #808395;
    border-radius: 100px;
    color: #808395;
    font-family: "Montserrat";
    font-size: 16px;
    position: relative;
    overflow: hidden;

    border: 1px solid #808395;
    p {
      flex-grow: 1;
      padding: 10px 20px;
      margin-bottom: 0px;
    }
    &__input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      display: block;
      width: 0;
      height: 0;
    }

    &__button {
      border: none;
      background: #5f8bff;
      padding: 10px 20px;
      color: white;
    }
  }
}
