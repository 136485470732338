.profile-card {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background: white;
  box-shadow: 0px 0px 29px rgba(231, 231, 231, 0.16);
  border-radius: 20px;
  margin-bottom: 30px;
  height: 100%;
}
