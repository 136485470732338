.chart {
  display: flex;
  justify-content: space-between;
  min-height: 227px;
  align-items: end;
  margin-top: auto;
  &__square {
    border-radius: 4px 4px 0px 0px;
    max-height: 190px;
    justify-content: space-between;
    position: relative;
    &-text {
      color: white;
      text-align: left;
      transform: rotate(-90deg);
      font-family: Unbounded;
      font-size: 14px;
      font-weight: 600;
      &-mins {
        text-align: center;
        color: #494c59;
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 400;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
