.bookies {
  &-grid {
    display: grid;
    grid-template-columns: repeat(4, 300px);
    grid-template-rows: auto;
    column-gap: 30px;
    margin-bottom: 40px;
    row-gap: 10px;
  }

  &__title {
    font-family: "Montserrat";
    max-width: 850px;
    font-size: 18px;
    line-height: 25px;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &-buttons {
    :first-child {
      margin-right: 10px;
    }
  }

  &-select {
    font-family: "Montserrat";
    font-size: 16px;
    line-height: 20px;
    appearance: none;
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
    background-color: transparent;
    background-image: url("../../../../../assets//svg/angle-down.svg");
    padding: 10px 20px;
    border-radius: 100px;
    border: 1px solid black;
    &-label {
      margin-bottom: 5px;
      font-size: 16px;
      font-family: "Montserrat";
    }
  }
}

@media (max-width: 1349px) {
  .bookies-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 991px) {
  .bookies-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .bookies-grid {
    grid-template-columns: 1fr;
  }
}