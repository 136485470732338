.statola-premium {
  display: flex;
  flex-direction: column;
  min-width: 410px;
  flex-basis: 410px;
  padding: 40px;
  background: white;
  &__title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
    max-width: 330px;
  }

  &-games {
    display: flex;
    flex-direction: column;
    &__item {
      display: flex;
      margin-bottom: 20px;

      p {
        margin-left: 40px;
        margin-bottom: 0px;
        font-family: "Montserrat";
        font-size: 16px;
      }
    }
  }

  &__button {
    display: flex;
    margin: 0 auto;
    width: min-content;
    white-space: nowrap;
    border: none;
    background: transparent;
    font-family: "Montserrat";
    font-size: 16px;
    color: #5f8bff;
    border-bottom: 1px solid #5f8bff;
  }
}
