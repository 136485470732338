.delete-modal {
  display: flex;
  flex-direction: column;
  &__text {
    text-align: center;
    font-weight: 600;
    color: #808395;
    font-family: "Montserrat";
    font-size: 16px;
    span {
      color: #5f8bff;
    }
  }

  &-buttons {
    display: flex;
    justify-content: space-between;
    :first-child {
      flex-grow: 1;
      margin-right: 5px;
    }
    :last-child {
      flex-grow: 1;
      margin-left: 5px;
    }
  }
}
