.team-ranked-filter {
  align-self: flex-start;
  cursor: pointer;
  display: flex;

  border-radius: 100px 100px 100px 100px;
  // border: 1px solid #808395;
  overflow: hidden;
  border-right: none;
  div:first-child {
    border-radius: 100px 0px 0px 100px;
    border: 1px solid #808395;
    border-right: none;
  }
  div:last-child {
    border-radius: 0px 100px 100px 0px;
    border: 1px solid #808395;
    border-left: none;
  }
  &__item {
    border-right: 1px solid #808395;
    padding: 10px;
    font-size: 14px;
    font-weight: 300;

    &-disabled {
      cursor: auto;
      border-top: 1px solid #808395;
      border-bottom: 1px solid #808395;
      border-right: none;
      border-left: none;
    }

    &_active {
      background: #cfff47;
    }
  }

  &__input {
    display: flex;
    padding: 10px;
    font-size: 14px;
    font-weight: 300;
    max-width: 55px;
    background: white;
    justify-content: center;
    text-align: center;
    align-items: center;
    border: 1px solid#5f8bff;
    padding: 10px;
    &-second {
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
    }
  }
}
