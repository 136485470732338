.other-filter {
  &-body {
    position: absolute;
    z-index: 3;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    border: 2px solid #5f8bff;
    width: 380px;
    background: white;
    border-radius: 10px;
    padding: 10px;
    flex-wrap: wrap;
  }

  &-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: 300;
    color: #494c59;
    padding: 10px;
    border-radius: 0px 100px 100px 0px;
    border: 1px solid #808395;
    span {
      margin-right: 10px;
    }

    &_active {
      background: #cfff47;
    }
  }

  &-grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, 92px); /* 2 */
    grid-gap: 5px 30px;
  }

  &__item {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    min-width: 110px;
    cursor: pointer;
    font-weight: 300;
    margin-right: 5px;
    padding: 10px 15px;
    border: 1px solid #808395;
    border-radius: 100px;
    margin-bottom: 10px;
    &_active {
      color: #494c59;
      background: #cfff47;
    }
  }

  &__button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #5f8bff;
    border-radius: 105px;
    color: white;
    font-family: "Montserrat";
    padding: 5px 30px;
    margin-left: auto;
    margin-top: auto;
  }
}
